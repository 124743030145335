import type { ICategory, IAuthor, IFilter, IFilterOption } from '@types';

export const useCatalog = () => {
  const { closeSideMenu } = useSideNav();

  const {
    pending,
    pendingCategories,
    categories,
    category,
    author,
    products,
    productsTotal,
    pagination,
    sort,
    filters,
    filtersActive,
  } = storeToRefs(useCatalogStore());

  const $router = useRouter();

  const $route = useRoute();

  const abortController = ref<any>(null);

  const { y: scrollTop } = useWindowScroll({ behavior: 'smooth' });

  const setCatalogQuery = async ({ option }: { option?: IFilterOption } = {}) => {
    const query = $route.query;

    delete query.page;

    if (option?.code === 'price') {
      delete query[option.code];
    } else if (option) {
      const selectedOptions = query[option.code]
        ? (query[option.code]
            ?.toString()
            ?.split(',')
            ?.map((option: string) => decodeURIComponent(option)) ?? [])
        : [];

      const selectedOptionIndex = selectedOptions?.indexOf(String(option.value));

      if (selectedOptionIndex !== undefined && selectedOptionIndex >= 0) {
        selectedOptions.splice(selectedOptionIndex, 1);
      } else {
        selectedOptions.push(String(option.value));
      }

      if (!selectedOptions?.length) {
        delete query[option.code];
      } else {
        query[option.code] = selectedOptions.map((option: string) => encodeURIComponent(option)).toString();
      }
    } else {
      Object.keys(query).forEach((code) => {
        if (!['sort', 'phrase', 'search'].includes(code)) {
          delete query[code];
        }
      });
    }

    await $router.push({ path: undefined, query, force: true });
  };

  const getCatalogCategory = async ({ categoryUrl }: { categoryUrl: string }): Promise<ICategory> => {
    try {
      const result = await fetchApiGet('/catalog/category', {
        data: {
          categoryUrl,
        },
      });

      category.value = result?.category;

      return result;
    } catch (error: any) {
      throw createError(error);
    }
  };

  /**
   * Fetch a specific author.
   * @param {string} authorUrlAlias - The alias of the author to fetch.
   * @returns {Promise<IAuthor>} The author data if successful, otherwise undefined.
   */
  const getCatalogAuthor = async ({ alias }: { alias?: string }): Promise<IAuthor> => {
    try {
      const result = await fetchApiGet('/catalog/author', {
        data: {
          alias,
        },
      });

      author.value = result?.author;

      return result;
    } catch (error: any) {
      throw createError(error);
    }
  };

  /**
   * Fetch catalog products
   * @param {Object} query - route query
   * @param {string} categoryId - catalog category id
   * @param {string} categoryUrl - route url path
   * @param {string} authorId - author id
   * @returns {Promise<any>} The author data if successful, otherwise undefined.
   */
  const getCatalog = async ({
    query,
    categoryId,
    categoryUrl,
    authorId,
  }: {
    query?: any;
    categoryId?: string | null;
    categoryUrl?: string | null;
    authorId?: string | null;
  } = {}): Promise<Object | void> => {
    if (pending.value) {
      return;
    }

    pending.value = true;

    if (query?.page <= 1) {
      delete query.page;
      await $router.replace({ query, force: true });
    }

    try {
      const result = await fetchApiGet('/catalog', {
        data: {
          ...query,
          categoryId,
          categoryUrl,
          authorId,
        },
      });

      if (query?.append) {
        products.value?.push(...result?.products);
        delete query.append;
        await $router.replace({ query, force: true });
      } else {
        products.value = result?.products;
        scrollTop.value = 0;
      }

      productsTotal.value = result?.productsTotal ?? 0;
      sort.value = result?.sort;
      pagination.value = result?.pagination;

      filters.value = authorId
        ? result?.filters?.filter((filter: IFilter) => filter?.code !== 'author')
        : result?.filters;

      filtersActive.value = authorId
        ? result?.filtersActive?.filter((filter: IFilter) => filter?.code !== 'author')
        : result?.filtersActive;

      return result;
    } catch (error: any) {
      throw createError(error);
    } finally {
      pending.value = false;
    }
  };

  const getFilterOptionsSearch = async ({ phrase, filterName }: { phrase?: string; filterName: string }) => {
    if (abortController.value) {
      abortController.value.abort();
    }

    const abortControllerNew = new AbortController();
    abortController.value = abortControllerNew;

    try {
      const result = await fetchApiGet('/filters/search', {
        signal: abortControllerNew.signal,
        data: {
          ...$route.query,
          phrase,
          filterName,
        },
      });

      return result;
    } catch (error: any) {
      throw createError(error);
    }
  };

  const getFilterOptionsMore = async (filterName: string) => {
    try {
      const result = await fetchApiGet('/filters/more', {
        data: {
          ...$route.query,
          filterName,
        },
      });

      if (filters.value?.[result.code]) {
        filters.value[result.code] = result;
      }

      return result;
    } catch (error: any) {
      throw createError(error);
    }
  };

  const getCategories = async () => {
    closeSideMenu();

    pendingCategories.value = true;

    try {
      const result = await fetchApiGet('/categories');

      categories.value = result?.categories;

      return result;
    } finally {
      pendingCategories.value = false;
    }
  };

  return {
    setCatalogQuery,
    getCatalogCategory,
    getCatalogAuthor,
    getCatalog,
    getFilterOptionsSearch,
    getFilterOptionsMore,
    getCategories,
  };
};
